.red-node .rst__rowContents {
  background-color: red;
}

#tootip {
  height: 20px;
  width: 100%;
  background-color: black;
  position: absolute;
  z-index: 2000;
  display: none;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.h-screen {
  height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.btn {
  margin: 5px !important;
}


.dragging {
  filter: blur(5px);
}

/* .rst__shadow {
  border-width: 3px;
  border-color: red;
  border-style: dashed;
} */

.rst__shadow:before {
  content: "";
  position: absolute;
  inset: 1px;
  background: conic-gradient(#00BFFF, #00BFFF);
  filter: blur(7px);
}
